import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import emailIcon from '../../../../../Assets/wes-login/emailIcon.svg';
import mesh3d from '../../../../../Assets/wes-login/mesh-3d.svg';
import nsdcTrust from '../../../../../Assets/wes-login/nsdc-trust.svg';
import { TOP_BAR_LINKS, TRENTIAL_PERKS } from './constants';
import LoginForm from './form';
import styles from './styles';

function Login() {
  return (
    <Box sx={{backgroundColor: '#FAFAFA', height: {xs: '100vh', sm: 'auto'}, display: 'flex', flexFlow: 'column'}}>
      <Box sx={styles.topbar}>
        <Grid container sx={{justifyContent: 'space-between', alignItems: 'center'}}>
          <Grid item>
            <Box component={'img'} src={nsdcTrust} sx={{height: '5rem' }} />
          </Grid>
          <Grid item sx={{display: 'flex', columnGap: {xs: '1rem', sm: '4rem'}, alignItems: 'center'}}>
            {TOP_BAR_LINKS.map((row) =>
                <Box
                  component={'button'}
                  className='wes-auth-caption'
                  sx={{all: 'unset', height: 'fit-content', cursor: 'pointer', color: '#131313 !important', fontWeight: '400 !important', whiteSpace: 'nowrap'}}
                  onClick={() => document.getElementById(row.elementId)?.scrollIntoView({behavior: 'smooth'})}
                >
                  {row.label}
                </Box>)
              }
              <Button href={`mailto:${process.env.REACT_APP_ORG_MAIL}`} disableRipple disableFocusRipple sx={{p: '.9rem 1.6rem', border: '1px solid'}} startIcon={<img src={emailIcon} />}>
                {'Contact For Support'}
              </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{pb: {xs: '3.5rem', sm: '2.2rem'}, alignItems: 'center'}}>
        <Grid item xs={12} blg={4.5} sx={styles.topGrid}>
          <Box sx={{width: {xs: '100%', blg: '150% !important'}, zIndex: '5'}}>
            <Typography className='wes-auth-title' sx={{color: '#000000 !important'}}>
                All-in-one
              {' '}
              <Box component='span' sx={{color: '#1C43C3'}}>
              credential verification platform
              </Box>
            </Typography>
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}>
            <Box sx={{display: 'flex', columnGap: '1rem', backgroundColor: '#fff', border: '1px solid rgba(238, 238, 238, 1)', mb: '1rem', width: 'fit-content', borderRadius: '16px', padding: {xs: '1.7rem', xl: '2.3rem'}}}>
              {
                TRENTIAL_PERKS.map((row, index) =>
                  <Box sx={{display: 'flex', columnGap: '3rem', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', alignItems: 'center', rowGap: '1.5rem', flexFlow: 'column'}}>
                      <Box component='img' src={row.imgSrc} sx={row.imgStyle} />
                      <Typography className='wes-auth-perks-text' sx={{color: '#212121 !important', textAlign: 'center'}}>
                        {row.label}
                      </Typography>
                    </Box>
                    {
                      index !== TRENTIAL_PERKS?.length - 1 &&
                      <Box sx={{backgroundColor: '#00000033', width: '1px', height: '50%'}} />
                    }
                  </Box>)
              }
            </Box>
          </Box>
        </Grid>
        <Grid
          item xs={12} blg={7.5} sx={{position: 'relative', order: {xs: -1, sm: 1}}}>
          <Box
            component={'img'} src={mesh3d} sx={{
              objectFit: 'contain',
              width: '100%',
              opacity: {xs: '1', sm: '.45'},
              mt: {xs: '15rem', sm: 0},
              mb: {xs: '2.2rem', sm: 0}
            }} />
          <Box sx={styles.loginWrapper}>
            <LoginForm />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
